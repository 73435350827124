import styles from "./Body.module.css";

const Body = () => {
  return (
    <div className={styles.mainContainer}>
      Welcome to the App, try navigating using the Navbar
    </div>
  );
};

export default Body;
